import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";
import ArticleWidget from "../../components/widgets/ArticleWidget";
import ArticleWidget2 from "../../components/widgets/ArticleWidget2";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useEffect, useState} from "react";
import SeoBlock from "../../components/SeoBlock";

export default function MyScreenResolution() {
    const { t } = useTranslation();
    const [screenResolution, setScreenResolution] = useState({ width: 0, height: 0 });
    const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        // Обновление состояния с размерами экрана при загрузке компонента
        updateScreenResolution();

        // Добавление слушателя события изменения размеров экрана
        window.addEventListener('resize', updateScreenResolution);

        // Очистка слушателя события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', updateScreenResolution);
        };
    }, []); // Пустой массив зависимостей означает, что эффект будет выполняться только при монтировании и размонтировании компонента

    const updateScreenResolution = () => {
        setScreenResolution({
            width: window.screen.width,
            height: window.screen.height
        });

        setViewportSize({
            width: document.documentElement.clientWidth,
            height: window.innerHeight
        });
    };

    return (
        <Layout>
            <Seo title={t('screenResolutionH1')} description={t('viewableScreenResolution')} image="../../services/my-screen-resolution.png" />
            <PageHeaderBlock h1="screenResolutionH1" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="ourServicesWord" firstChildUrl={'/services'} secondChild="screenResolution" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <Row>
                            <Col xl={6} className="text-center text-xxl-start">
                                <h2 className="mb-3 mb-xxl-4">{t('screenResolution')}:</h2>
                                <p className="mb-2 mb-xxl-3">{t('widthWord')}: <b>{screenResolution.width} px</b></p>
                                <p>{t('heightWord')}: <b>{screenResolution.height} px</b></p>
                            </Col>
                            <Col xl={6} className="text-center text-xxl-start">
                                <h2 className="mt-4 mt-md-0 mb-3 mb-xxl-4">{t('viewableScreenResolution')}:</h2>
                                <p className="mb-2 mb-xxl-3">{t('visibleAreaWidth')}: <b>{viewportSize.width}px</b></p>
                                <p>{t('visibleAreaHeight')}: <b>{viewportSize.height}px</b></p>
                            </Col>
                        </Row>
                        <div className="my-screen-resolution__popular grad py-3 py-md-4 rounded-4 px-3 px-md-4 px-xxl-5 py-xxl-5 my-3 my-xl-4 my-xxl-5">
                            <h2 className="mb-2 mb-md-3 mb-xxl-4 py-2 pt-xxl-1 pb-xxl-3">{t('popularScreenResolution')}:</h2>
                            <table width='100%' className="rounded-4">
                                <thead>
                                <tr>
                                    <th>{t('deviceWord')}</th>
                                    <th>{t('screenResolutionWord')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Samsung Galaxy S23 Ultra</td>
                                    <td>412x806px</td>
                                </tr>
                                <tr>
                                    <td>LG V50 ThinQ</td>
                                    <td>412x762px</td>
                                </tr>
                                <tr>
                                    <td>iPad 6</td>
                                    <td>1024x665px</td>
                                </tr>
                                <tr>
                                    <td>Huawei MatePad 11</td>
                                    <td>1024x616px</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
            <SeoBlock text="viewableScreenResolutionSeoText" />
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;